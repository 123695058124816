var kitchen = {
    container: {
        height: "60%",
        width: "80%",
        backgroundColor: "yellow",
        marginLeft: "10%",
        marginRight: "10%"
    }
}

export default kitchen;