var upper = {
    container: {
        width: "80%",
        marginLeft: "10%",
        marginRight: "10%",
        marginTop: 40,
        height: "20%"
    }
}

export default upper;