import upgrades from "../GameLogic/PresetsAndTemplates/Upgrades"

var upgrade = {
    container: {
        width: "100%",
        height: "100%"
    },
    scollView: {
        height: 11,
        width: "100%",
        backgroundColor: "#F26157"
    }
}

export default upgrade;