import React from 'react';
import { View, Text, Button } from 'react-native';

function LowerHud(props) {
	return (
		<View>
			{/* <Text>This is the Lower hud</Text>
            <Button 
             title="Settings"
             onPress={() => {
                props.gameLogic.GameDriver.leftGamePlay(true);
                props.navigation.navigate("Settings");
             }}
            />
            <Button
             title="LeaderBords"
             onPress={() => {
                props.gameLogic.GameDriver.leftGamePlay(true);
                props.navigation.navigate("Leaderbords");
             }}
            />
            <Button
             title="Share"
             onPress={() => {
                props.gameLogic.GameDriver.leftGamePlay(true);
                props.navigation.navigate("Share");
             }}
            /> */}
		</View>
	);
}

export default LowerHud;
